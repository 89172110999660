import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class VideoV2 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'

		return <div className="ltn__about-us-area section-bg-11 bg-image-right-before pt-50 pb-50 mt-100 mb-100">
			<div className="img-captions">
				Branded Cities - SOHO Grand
			</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Union Local 3 and Local 137</h6>
								<h1 className="section-title">EXPERT SIGN INSTALLATION</h1>
								<p>We offer expert commercial sign installation on all types of signage. Our experienced team of sign installers and electricians will ensure that your signs are safely and properly installed.
								</p>
								<p><b>We have 40 years of experience installing signs of all types and sizes including:</b>
								</p>
							</div>
							<ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
								<li>
									<i className="icon-done" />
									Digital billboards and LED screens
								</li>
								<li>
									<i className="icon-done" />
									Billboard pole structures

								</li>
								<li>
									<i className="icon-done" />
									Wall structures for billboards

								</li>
								<li>
									<i className="icon-done" />
									Large billboard banners and graphics

								</li>
								<li>
									<i className="icon-done" />
									Building directories

								</li>
								<li>
									<i className="icon-done" />
									Window graphics

								</li>

								<li>
									<i className="icon-done" />
									Adhesive vinyl


								</li>
								<li>
									<i className="icon-done" />
									ADA signs


								</li>
								<li>
									<i className="icon-done" />
									Monument signs


								</li>
								<li>
									<i className="icon-done" />
									Traffic signs


								</li>
								<li>
									<i className="icon-done" />
									Regulatory signs


								</li>
								<li>
									<i className="icon-done" />
									Wayfinding Signs

								</li>

							</ul>

							{/* <p>When it comes to ensuring your business gets noticed, the importance of professional and properly installed signage cannot be overstated. Whether you're a small business just starting out or a well-established corporation, hiring professional sign installers can make all the difference. 
</p>

<p>Quality assurance, safety compliance, time and cost-efficiency and permit and zoning knowledge are all important aspects of sign installation. Let us take care of your signage needs and you will have peace of mind knowing that your project is in good hands.  
</p> */}
							{/* <div className="  ltn__animation-pulse2 text-center mt-30">
			            <a className="ltn__video-play-btn bg-white--- ltn__secondary-bg" href="https://www.youtube.com/embed/HnbMYzdjuBs?autoplay=1&showinfo=0" data-rel="lightcase">
			              <i className="icon-play  ltn__secondary-color--- white-color" />
			            </a>
			          </div> */}
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">

						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default VideoV2