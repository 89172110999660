import React, { Suspense } from 'react';
import Navbar from './global-components/navbar-v4';
import VideoV4 from './section-components/video-v4';
import ExpertSignInstall from './section-components/video-v2';
import TopBar from './global-components/topbar';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const Home_V5 = () => {

    let publicUrl = process.env.PUBLIC_URL + '/'

    return <div>
        <Helmet>
            <title>Interboro Signs &amp; Electric Inc.</title>
            <link rel="icon" href={publicUrl + "favicon.ico"}/>
            <meta name="description" content={`Learn more about Interboro services`} />
            <meta property="og:title" content={`Home - Interboro Signs &amp; Electric Inc.`} />
            <meta property="og:description" content={`Learn more about Interboro services`} />

        </Helmet>
        <Suspense fallback={<div>Loading...</div>}>
            <TopBar />
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
            <VideoV4 />
            <Suspense fallback={<div>Loading...</div>}>
                <ExpertSignInstall />
            </Suspense>
            <Footer />
        </Suspense>
    </div>
}

export default Home_V5

