import React from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
// import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import { Helmet } from 'react-helmet';

const ContactV1 = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'
    return <div>
        <Helmet>
            <title>Contact - Interboro Signs &amp; Electric Inc.</title>
            <link rel="icon" href={publicUrl + "favicon.ico"} />
            <meta name="description" content={`Learn more about Interboro services`} />
            <meta property="og:title" content={`Home - Interboro Signs &amp; Electric Inc.`} />
            <meta property="og:description" content={`Learn more about Interboro services`} />

        </Helmet>
        <TopBar />
        <Navbar />
        <PageHeader headertitle="Contact Us" subheader="Contact" />
        <ContactInfo />
        <ContactForm />
        <Map />
        {/* <CallToActionV1 /> */}
        <Footer />
    </div>
}

export default ContactV1

