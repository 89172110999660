import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch, Link } from "react-router-dom";
import ScrollToSection from "./scrollToSection";
import HomeV5 from './components/home-v5';
import About from './components/about';
import ProjectDetails from './components/product-details';
import Projects from './components/projects'
import Contact from './components/contact';

class Root extends Component {
    render() {
        return (
            <Router basename="/">
                <div>
                    <Switch>
                        <Route exact path="/" component={HomeV5} />
                        <Route exact path="/contact" component={Contact} />
                        <Route path="/about" component={About} />
                        <Route path="/projects" component={Projects} />
                        <Route path="/project-details" component={ProjectDetails} />
                    </Switch>
                </div>
                <ScrollToSection />
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('Interboro'));
