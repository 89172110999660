import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class NavbarV3 extends Component {

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		const currentUrl = window.location.pathname;
		return (
			<div>
				<header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-2">
					<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
						<div className="container">
							<div className="row">
								<div className="col">
									<div className="site-logo-wrap">
										<div className="site-logo go-top" style={{ width: '210px' }}>
											<Link to="/"><img src={publicUrl + "assets/img/interboro.png"} alt="Logo" /></Link>
										</div>
										<div style={{
											padding: '0px 0px 0px 20px',
											color: 'white', fontWeight: 'bold',
											margin: 'auto 0'
										}}>An M/WBE Company
											
										</div>
									</div>
								</div>
								<div className="col header-menu-column menu-color-white">
									<div className="header-menu d-none d-xl-block go-top">
										<nav>
											<div className="ltn__main-menu" style={{ float: 'right' }}>
												<ul>
													<li><Link to="/">Home</Link>

													</li>
													<li ><Link to="/about">About Us</Link>

													</li>
													

													<li><Link to="/projects">Projects</Link>

													</li>

													<li><Link to="/contact">Contact Us</Link></li>

												</ul>
											</div>
										</nav>
									</div>
								</div>
								<div className="col--- ltn__header-options ltn__header-options-2 ">

									<div className="mobile-menu-toggle d-xl-none">
										<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
											<svg viewBox="0 0 800 600">
												<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
												<path d="M300,320 L540,320" id="middle" />
												<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
											</svg>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

				<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
					<div className="ltn__utilize-menu-inner ltn__scrollbar">
						<div className="ltn__utilize-menu-head">
							<div className="site-logo">
								<Link to="/"><img src={publicUrl + "assets/img/interboro.png"} alt="Logo" /></Link>
							</div>
							<button className="ltn__utilize-close">×</button>
						</div>
					
						<div className="ltn__utilize-menu">
							<ul>
								<li><a href="/">Home</a>

								</li>
								<li><Link to="/about">About Us</Link>

								</li>
								
								<li><Link to="/projects">Projects</Link></li>


								<li><Link to="/contact">Contact US</Link></li>
							</ul>
						</div>
						
					</div>
				</div>
			</div>
		)
	}
}


export default NavbarV3