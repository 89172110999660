import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV2 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pt-0 pb-80 ">
			<div className="container">
				<div className="row">

					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-30">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Union Local 3</h6>
								<h1 className="section-title">LICENSED ELECTRICIANS</h1>
								<p>INTERBORO utilizes qualified and licensed electricians. As a Local 3 employer, our men are trained to perform their work with skill and professionalism. Our team also comprises of trained Daktronics technicians who service Daktronics products throughout the Tri-State area. Our electricians are trained in: </p>
							</div>
							<ul className="ltn__list-item-1 ltn__list-item-1-before clearfix">
								<li> Electrical maintenance and repair</li>
								<li>New construction installations</li>
								<li>LED sign installation and maintenance</li>
								<li>Digital sign installation and maintenance</li>
							</ul>

							<ul className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
								<li>
									<a href={publicUrl + "assets/img/img-slide/11.jpg"} data-rel="lightcase:myCollection">
										<img src={publicUrl + "assets/img/img-slide/11.jpg"} alt="Brooklyn Cyclones Stadium / Maimonides Park - Surf Ave Brooklyn" />
										<div className="img-captions img-caption-back">
										Brooklyn Cyclones Stadium / Maimonides Park - Surf Ave Brooklyn
										</div>
									</a>
								</li>
								{/* <li>
			              <a href={publicUrl+"assets/img/img-slide/12.jpg"} data-rel="lightcase:myCollection">
			                <img src={publicUrl+"assets/img/img-slide/12.jpg"} alt="Image" />
			              </a>
			            </li> */}
								<li>
									<a href={publicUrl + "assets/img/img-slide/13.jpg"} data-rel="lightcase:myCollection">
										<img src={publicUrl + "assets/img/img-slide/13.jpg"} alt="Madison Square Garden - Daktronics LED" />
										<div className="img-captions img-caption-back">
										Madison Square Garden - Daktronics LED
										</div>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-right">
							<img src={publicUrl + "assets/img/others/lic_elec.jpg"} alt="Branded Cities - 4 Times Square" />
							<div className="img-captions img-caption-back">
							Branded Cities - NASDAQ - 4 Times Square
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default AboutV2