import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactInfo extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__contact-address-area mb-50">
			<div className="container">
				<div className="row">
					<div className="col-lg-4">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
							<div className="ltn__contact-address-icon">
								<img src={publicUrl + "assets/img/icons/10.png"} alt="Icon Image" />
							</div>
							<h3>Email Address</h3>
							<p>
								cindy@interborosigns.com <br />
								mahindra@interborosigns.com
							</p>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
							<div className="ltn__contact-address-icon">
								<img src={publicUrl + "assets/img/icons/11.png"} alt="Icon Image" />
							</div>
							<h3>Phone Number</h3>
							<p>
								718-366-7901 <br /> <br />
							</p>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
							<div className="ltn__contact-address-icon">
								<img src={publicUrl + "assets/img/icons/12.png"} alt="Icon Image" />
							</div>
							<h3>Office Address</h3>
							<p style={{marginBottom:'0.7em'}}>
							39-16 23rd Street, Long Island City  NY, 11101
							</p>
							<p style={{marginBottom:'0.7em'}}>
							1501 Broadway, New York,   NY 10036
							</p>
							{/* <div class="row" >
								<div className="col-lg-12" style={{marginBottom:'10px'}}>
									39-16 23rd Street, Long Island City  NY, 11101
								</div>
								<br></br>
								<div className="col-lg-12">
									1501 Broadway, New York,   NY 10036
								</div>
							</div> */}

						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default ContactInfo