import React, { useState, useEffect } from "react";
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import AboutV1 from './section-components/about-v1';
import TopBar from './global-components/topbar';
import Footer from './global-components/footer';
import Category from './section-components/category-v1';
import serviceData from "../data/services.json";
import { useHistory } from "react-router-dom";
import Aboutv2 from './section-components/about-v2';
import { Helmet } from 'react-helmet';

const About_v1 = () => {

    const [data, setData] = useState(null);

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    useEffect(() => {
        // In this case, the JSON data is already imported statically
        setData(serviceData);

    }, []);

    const history = useHistory();

    const navigateToSection = (sectionId) => {
        history.push(`/about#${sectionId}`); // Navigate to a new route with a hash
    };
    return (
        <div>
            <Helmet>
                <title>About - Interboro Signs &amp; Electric Inc.</title>
                <link rel="icon" href={publicUrl + "favicon.ico"} />
                <meta name="description" content={`Learn more about Interboro services`} />
                <meta property="og:title" content={`Home - Interboro Signs &amp; Electric Inc.`} />
                <meta property="og:description" content={`Learn more about Interboro services`} />

            </Helmet>
            <TopBar />
            <Navbar />
            <PageHeader headertitle="About Us" />
            {data && <AboutV1 />}
            <Aboutv2 />
            {data && <Category data={data} />}

            <Footer />
        </div>
    )
}

export default About_v1

